import {HttpClient, HttpParams} from '@angular/common/http';

import {API} from 'src/app/shared/app-constant';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {isEmpty} from 'lodash';
import {LocalStorageService} from "../local-storage.service";

@Injectable({
  providedIn: 'root',
})
export class ProponentApplicationService {
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
  }

  list(status?: any): Observable<any> {
    let url = environment.baseURL + API.GET_PROPONENT_APPLICATION;
    let params = new HttpParams();
    if (!isEmpty(status)) params = params.append('status', status);
    return this.http.post<any[]>(url, {}, { params });
  }

  update(proposalNo: any, status: string): Observable<any> {
    let params = new HttpParams()
      .append('proposalNo', proposalNo)
      .append('Status', status);
    return this.http.post<any[]>(
      environment.baseURL + API.UPDATE_PROPONENT_APPLICATION,
      {},
      { params }
    );
  }

  updateMoEFCCFileNo(
    proposalNo: any,
    moefccFileNumber: any,
    isState?: boolean
  ): Observable<any> {
    let params = new HttpParams()
      .append('proposalNo', proposalNo)
      .append('moefccFileNumber', moefccFileNumber);

    if (isState) params = params.append('isState', isState);

    return this.http.post<any[]>(
      environment.baseURL + API.UPDATE_PROPONENT_APPLICATION,
      {},
      { params }
    );
  }

  updateCertificateUrl(
    id: any,
    certificateUrl: any,
    proposalNo: string,
    stepId: string
  ): Observable<any> {
    const isForUrl1 = this.localStorageService.get('isForUrl1') == 'YES';
    const params = new HttpParams()
      .append('id', id)
      .append('url', certificateUrl)
      .append(
        'type',
          (certificateUrl?.includes('fc_') && +stepId < 100000) || isForUrl1
          ? 'certificate_url1'
          : 'certificate_url'
      );
    this.localStorageService.remove('isForUrl1');
    return this.http.post<any[]>(
      environment.baseURL + API.UPDATE_PROPONENT_CERTIFICATE_URL,
      {},
      { params }
    );
  }

  updateFcCorrigendumCertificateUrl(
    corrigendumId: any,
    certificateUrl: any,
  ): Observable<any> {
    const params = new HttpParams()
      .append('corrigendumId', corrigendumId)
      .append('url', certificateUrl)
    return this.http.post<any[]>(
      environment.baseURL + API.UPDATE_FC_CORRIGENDUM_CERTIFICATE_URL,
      {},
      { params }
    );
  }

  edsProposals(): Observable<any> {
    let url = environment.baseURL + API.GET_PROPONENT_EDS_PROPOSALS;
    return this.http.post<any[]>(url, {}, {});
  }

  getProposalDivision(applicationId: any): Observable<any> {
    let url = environment.baseURL + API.GET_PROPOSAL_DIVISIONS;
    const params = new HttpParams().append('application_id', applicationId);
    return this.http.post<any[]>(url, {}, { params });
  }

  getProposalofProjects(applicationId: any): Observable<any> {
    let url = environment.baseURL + API.GET_PROPOSAL_PROJECTS;
    const params = new HttpParams().append('applicationId', applicationId);
    return this.http.post<any[]>(url, {}, { params });
  }

  updateFormDocument(refId: any, refType: any, file: any) {
    const url = `${environment.baseURL}${API.UPDATE_PP_APPLICATION_DOCUMENT}?refType=${refType}&refId=${refId}`;

    const myFile = new File([file], 'adssdfasdf', {
      type: 'application/pdf',
    });

    const formData: FormData = new FormData();
    formData.append('formData', myFile);

    return this.http.post<any>(url, formData);
  }
}
