import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  clearAllRequest() {
    this.requests = [];
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);
    if (!req.url.includes('/ask'))
      this.loaderService.isLoading.next(true);
    return next.handle(req).pipe(
      tap(
        (response: any) => {
          if (response instanceof HttpResponse) {
            this.removeRequest(req);
          }
        },
        (error) => {
          const status = error?.status || '';
          switch (status) {
            case 401:
              this.clearAllRequest();
              break;
            default:
              this.removeRequest(req);
              break;
          }
        }
      )
    );
  }
}
