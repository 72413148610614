import { API_CRZ, API_JS_DASHBOARD, DASHBOARD, PAGINATION, ROLES } from 'src/app/shared/app-constant';
import { HttpClient, HttpParams } from '@angular/common/http';

import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ValidationUtility } from "../../utils/validation.utility";
import { environment } from 'src/environments/environment';
import { select, Store } from "@ngrx/store";
import { isDashboardProposalsLoaded } from "../../state/dashboard-state/dashboard-selectors";
import { AppState } from "../../state/app-state/app-reducer";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class JsDashboardService {
  isCrzRole: boolean = false;
  processHistoryId: any;

  recommendationDataReceived: Subject<any> = new Subject<any>();
  isProposalsLoaded: boolean = false;

  constructor(private http: HttpClient, private validationUtil: ValidationUtility, private store: Store<AppState>, private route: ActivatedRoute,) {
    this.store.pipe(select(isDashboardProposalsLoaded)).subscribe({
      next: isProposalsLoaded => this.isProposalsLoaded = isProposalsLoaded
    });
    this.route.queryParams.subscribe((params) => {
      this.processHistoryId = params['process_history'];
    });
    this.checkRole();
  }

  async checkRole() {
    this.isCrzRole = await this.validationUtil.isRole(ROLES.MS_CRZ) || await this.validationUtil.isRole(ROLES.OE_CRZ) || await this.validationUtil.isRole(ROLES.MS_CRZ_MOEFCC) || await this.validationUtil.isRole(ROLES.SO_CRZ_MOEFCC) || await this.validationUtil.isRole(ROLES.DD_CRZ_MOEFCC);
  }

  proposalCount(userId: any, isCurrent: any): Observable<GenericResponse> {
    let param = new HttpParams()
      .append('user_id', userId)
      .append('is_current', isCurrent);
    let url = API_JS_DASHBOARD.PROPOSAL_COUNT;

    if (this.isCrzRole) url = API_CRZ.GET_APPLICATIONS_LIST_COUNT;

    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${url}`,
      {},
      { params: param }
    );
  }



  proposalList(
    userId: any,
    sectorId: any,
    category: any,
    clearanceId?: any,
    status?: string
  ): Observable<any> {
    let param = new HttpParams().append('user_id', userId);
    if (category) {
      param = param.append('category', category);
    }
    if (sectorId) {
      param = param.append('sector_id', sectorId);
    }
    if (clearanceId) {
      param = param.append('clearance_id', clearanceId);
    }
    if (status) {
      param = param.append('status', status);
    }

    // if (this.isProposalsLoaded) return this.store.pipe(select(selectProposalId(this.processHistoryId)));

    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${API_JS_DASHBOARD.PROPOSAL_LIST}`,
      {},
      { params: param }
    );
  }

  dashboardProposalList(params: any) {
    return this.http.post<GenericResponse>(environment.baseURLProcessEngine + DASHBOARD.PROPOSAL_LIST, {}, {
      params: params
    });
  }

  proposalListCrz(
    userId: any,
    is_sczma: any,
    category: any,
    is_mom: any,
    is_accepted?: any,
    in_clearance?: any
  ): Observable<any> {
    let params = new HttpParams().append('user_id', userId);
    let url = API_CRZ.GET_APPLICATIONS_LIST;
    if (category) {
      params = params.append('category', category);
    }
    if (is_sczma) {
      params = params.append('is_sczma', is_sczma);
    }
    if (is_mom) {
      params = params.append('is_mom', is_mom);
    }
    if (is_accepted) {
      params = params.append('is_accepted', is_accepted);
    }

    if (in_clearance) {
      params = params.append('in_clearance', in_clearance);
    }
    url = API_CRZ.GET_APPLICATIONS_LIST_V3;

    // if (this.isProposalsLoaded) return this.store.pipe(select(selectProposalId(this.processHistoryId)));

    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${url}`,
      {},
      { params }
    );
  }

  getListCrzHistory(
    userId: any,
    is_sczma: any,
    category: any,
    is_mom: any,
    is_accepted?: any,
    from_date?: any,
    to_date?: any,
    in_clearance?: any
  ): Observable<GenericResponse> {
    let param = new HttpParams().append('user_id', userId);
    if (category) {
      param = param.append('category', category);
    }
    if (is_sczma) {
      param = param.append('is_sczma', is_sczma);
    }
    if (is_mom) {
      param = param.append('is_mom', is_mom);
    }
    if (is_accepted) {
      param = param.append('is_accepted', is_accepted);
    }
    if (from_date) {
      param = param.append('from_date', from_date);
    }
    if (to_date) {
      param = param.append('to_date', to_date);
    }
    if (in_clearance) {
      param = param.append('in_clearance', in_clearance);
    }

    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${API_CRZ.GET_APPLICATIONS_LIST_CRZ_HISTORY}`,
      {},
      { params: param }
    );
  }

  proposalHistoryList(
    userId: any,
    sectorId: any,
    category: any,
    fromDate?: any,
    toDate?: any,
    clearanceId?: any,
    status?: any
  ): Observable<GenericResponse> {
    let param = new HttpParams().append('user_id', userId);
    if (category) {
      param = param.append('category', category);
    }
    if (sectorId) {
      param = param.append('sector_id', sectorId);
    }
    if (fromDate) {
      param = param.append('from_date', fromDate);
    }
    if (toDate) {
      param = param.append('to_date', toDate);
    }
    if (clearanceId) {
      param = param.append('clearance_id', clearanceId);
    }
    if (status) {
      param = param.append('status', status);
    }
    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${API_JS_DASHBOARD.PROPOSAL_HISTORY_LIST}`,
      {},
      { params: param }
    );
  }

  getSectorList(): Observable<GenericResponse> {
    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${API_JS_DASHBOARD.SECTOR_LIST}`,
      {}
    );
  }

  getClearanceList(
    category: any,
    workgroupId: any
  ): Observable<GenericResponse> {
    let param = new HttpParams().append('workgroupId', workgroupId);
    if (category) {
      param = param.append('category', category);
    }
    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${API_JS_DASHBOARD.CLEARANCE_LIST}`,
      {},
      { params: param }
    );
  }

  sendProposalsToSeiaa(proposals: any): Observable<GenericResponse> {
    let url = environment.baseURLProcessEngine + API_JS_DASHBOARD.SEND_PROPOSAL_TO_SEIAA;
    let body = {
      "proposalNumbers": proposals
    };
    return this.http.post<GenericResponse>(url, body)
  }

  getSentFiles(data: any, page = 0, size = PAGINATION.PER_PAGE): Observable<any> {
    let url = environment.baseURLProcessEngine + "jsdashboard/getSentFiles";
    let params: any = {
      ...data, page, size
    }
    return this.http.post(url, {}, { params: params });
  }

}
