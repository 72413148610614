import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { projectDetails } from 'src/app/models/project/project-detail.model';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { API } from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClient) { }

  getListOfProject(page: number, size: number): Observable<any> {
    let url = environment.baseURL + API.GET_PROJECT;
    const param = new HttpParams().append('page', page).append('size', size);
    return this.http.post<GenericResponse>(url, { params: param });
  }

  postSaveProject(body: projectDetails): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_PROJECT;
    return this.http.post<GenericResponse>(url, body);
  }

  getProjectDetail(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_PROJECT_DETAIL_BY_ID;
    const param = new HttpParams().append('id', id);
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  getProject(project_id: any): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(environment.baseURL + API.GET_PROJECT_BY_ID, {}, { params: { project_id } });
  }

  getClearenceStats(project_id: any = ''): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_CLEARENCE_COUNT;
    const param = new HttpParams().append('project_id', project_id);
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  copyCaf(caf_id: number, amendment_form: string) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_CAF, {}, { params: { caf_id, amendment_form } });
  }

  copyProposal(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_PROPOSAL, {}, { params: { proposalId } });
  }

  copyProposalECPartC(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_PROPOSAL_EC_PART_C, {}, { params: { proposalId } });
  }

  copyCRZProposal(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_CRZ_PROPOSAL, {}, { params: { proposalId } });
  }

  copyFCFormAProposal(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_FC_FORM_A_PROPOSAL, {}, { params: { proposalId } });
  }

  copyFCFormBProposal(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_FC_FORM_B_PROPOSAL, {}, { params: { proposalId } });
  }

  isProposalValidToProceed(proposalId: any, clearenceId: any) {
    return this.http.get(environment.baseURL + API.GET_PROPOSAL_VALIDITY_TO_PROCEED, { params: { proposalId, clearenceId } })
  }

  copyFCFormCProposal(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_FC_FORM_C_PROPOSAL, {}, { params: { proposalId } });
  }

  copyFCFormDProposal(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_FC_FORM_D_PROPOSAL, {}, { params: { proposalId } });
  }

  copyFCFormEProposal(proposalId: number) {
    return this.http.post<GenericResponse>(environment.baseURL + API.COPY_FC_FORM_E_PROPOSAL, {}, { params: { proposalId } });
  }

  deleteProponant(appId: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_FLAG_PROPONENT_APPLICATION;
    let param: HttpParams = new HttpParams()
      .append('appId', appId);
    return this.http.post<GenericResponse>(url, {}, { params: param });

  }

  updateproject(projectId: any, data: any) {
    let URL = environment.baseURL + API.UPDATE_PROJECT;
    let param: HttpParams = new HttpParams()
      .append('id', projectId)
      .append('projectName', data);
    return this.http.post<GenericResponse>(URL, {}, { params: param });

  }
}
