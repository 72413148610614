<div class="chatbot-container" *ngIf="isOpen">
  <div class="chat-header">
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <h3 class="mb-0">Pari A.I.</h3>
      </div>
      <div class="col-2 d-flex justify-content-end align-items-center">
        <span
          class="cursor-pointer me-2"
          (click)="isOpen = !isOpen"
          data-toggle="tooltip"
          data-placement="left"
          title="Close"
        >
          &#x2716;
        </span>
      </div>
    </div>
  </div>
  <div class="chat-messages" #chatMessages>
    <p class="text-center">{{ currentDate }}</p>
    <div
      *ngFor="let message of messages"
      [ngClass]="{
        'user-message': message.sender === 'user',
        'bot-message': message.sender === 'bot'
      }"
    >
      <p class="mb-1">{{ message?.text }}</p>
      <p class="fs-10 opacity-50 mb-0 text-end">
        {{ message?.timestamp }}
      </p>
    </div>
  </div>
  <div *ngIf="loading" class="loading-dots">
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </div>
  <div class="chat-input">
    <input
      type="text"
      [(ngModel)]="userInput"
      (keyup.enter)="sendMessage()"
      placeholder="Ask Pari A.I."
    />
    <button (click)="sendMessage()">Send</button>
  </div>
</div>

<button
  *ngIf="!isOpen"
  class="btn btn-primary floating-button fs-16"
  (click)="isOpen = !isOpen"
>
  Pari A.I.
</button>
