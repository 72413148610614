import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { AiService } from 'src/app/services/ai-engine/ai-service';

interface Message {
  text: string;
  sender: 'user' | 'bot';
  timestamp?: string;
}

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})

export class ChatbotComponent implements OnInit, AfterViewChecked {
  isOpen: boolean = false;
  messages: Message[] = [];
  userInput: string = '';
  loading: boolean = false;
  @ViewChild('chatMessages') private chatMessagesContainer!: ElementRef;
  currentDate: string = moment(new Date().toLocaleDateString()).format('DD/MM/YYYY');


  ngOnInit(): void {
  }

  constructor(private aiService: AiService) { }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  // Mock response, can be replaced with actual API call
  getBotResponse(message: string) {
    this.aiService.getChatBotResponse({ question: message }).subscribe({
      next: response => {
        const bottimestamp = new Date().toLocaleTimeString();
        this.messages.push({ text: response?.response, sender: 'bot', timestamp: bottimestamp });
        this.loading = false;
      }
    }); // Placeholder response
  }

  sendMessage() {
    if (this.userInput.trim()) {
      // Add user message to the chat
      const timestamp = new Date().toLocaleTimeString();
      this.messages.push({ text: this.userInput, sender: 'user', timestamp });
      this.loading = true;
      const input: string = this.userInput;

      // Add bot response to the chat after a delay (simulate async)
      setTimeout(() => this.getBotResponse(input), 500);

      // Clear the input field
      this.userInput = '';
    }
  }

  private scrollToBottom(): void {
    try {
      if (this.chatMessagesContainer)
      this.chatMessagesContainer['nativeElement']['scrollTop'] = this.chatMessagesContainer?.nativeElement?.scrollHeight;
    } catch (err) {
      console.error('Scroll to bottom failed', err);
    }
  }

}