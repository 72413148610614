<div class="page-loader" *ngIf="loaderService.isLoading | async">
  <div class="page-loader-inner">
    <div class="spinner-border text-primary" role="status"></div>
    <img
      width="60"
      src="./assets/images/page-loader.png"
      alt=""
      aria-hidden="true"
    />
  </div>
</div>
<div class="d-flex flex-column">
  <div
    class="text-center text-white"
    [ngClass]="onlineStatus ? 'bg-success' : 'bg-danger'"
    *ngIf="showDiv"
  >
    <b>{{ onLineMessage }}</b>
  </div>
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <router-outlet></router-outlet>
</div>
