import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty } from 'lodash';

import { HomeApprovalMaster } from 'src/app/models/home/home-approval-master.model';
import { HomeBullitenBoardMaster } from 'src/app/models/home/home-bulliten-board-master.model';
import { HomeHeroMaster } from 'src/app/models/home/home-hero-master.model';
import { HomeKPIMaster } from 'src/app/models/home/home-kpi-master.model';
import { HomeMediaMaster } from 'src/app/models/home/home-media-master.model';
import { HomeRecentMaster } from 'src/app/models/home/home-recent-master.model';
import { HomeUpdateMaster } from 'src/app/models/home/home-update-master.model';
import { HomeUsefulLinkMaster } from 'src/app/models/home/home-useful-link-master.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TRACK_YOUR_PROPOSAL_MENU } from 'src/app/shared/app-constant';
import { HomeServiceService } from './home-service.service';
import { RouterUtility } from '../../utils/router.utility';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseFormComponent implements OnInit {
  @ViewChild('modiModalPopup') model: any;
  responsiveOptions: {
    breakpoint: string;
    numVisible: number;
    numScroll: number;
  }[];
  mediaMasterList: HomeMediaMaster[] = [];

  galleryItems: any = [];
  homeApproval: HomeApprovalMaster[] = [];
  homeRecent: HomeRecentMaster[] = [];
  homeUpdate: HomeUpdateMaster[] = [];
  heroMaster: HomeHeroMaster[] = [];
  KPIMaster: HomeKPIMaster[] = [];
  recentUpload: HomeBullitenBoardMaster[] = [];
  homeUseFulLink: HomeUsefulLinkMaster[] = [];
  isGalleryPopupShow: boolean = false;
  selectedGallery: any;
  allObjects: any = [];
  trackProposalUrl: string;
  trackYourProposalMenu: Array<any> = TRACK_YOUR_PROPOSAL_MENU;
  imageArrayToDisplay: string[] = [];

  constructor(
    private service: HomeServiceService,
    private modalService: NgbModal,
    private localstorageService: LocalStorageService,
    private homeService: HomeServiceService,
    public override route: ActivatedRoute,
    public override router: Router,
    public override routerUtility: RouterUtility
  ) {
    super(router, routerUtility, route);
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
    this.trackProposalUrl = this.trackYourProposalMenu.find(
      (item) => item.abbr === 'search_by_singlewindow_proposal_no'
    ).url;
  }

  ngOnInit(): void {
    this.getHeroMaster();
    this.getHomeApproval();
    this.getKPIMaster();
    this.getHomeUpdateMaster();
    this.getHomeRecentMaster();
    this.getHomeRecentUploads();
    this.getMediaMaster();
    this.getUsefulLink();
    if (isEmpty(this.localstorageService.get('POPUP_DISMISSED'))) {
      setTimeout(() => {
        this.modiModalPopup();
      }, 2000);
    }
    this.imageArrayToDisplay = this.galleryItems.slice(0, this.totalImgView);
    setTimeout(() => {
      this.getTime();
    }, 10000);
  }

  public totalImgView: number = 6;

  getMediaMaster() {
    this.homeService.getMediaMaster().subscribe((res) => {
      res.data.map((ele: any) => {
        this.galleryItems.push(ele);
      });

      this.allObjects = this.allObjects.concat(this.galleryItems);
    });
  }

  getHomeApproval() {
    this.service.getHomeApproval().subscribe((res) => {
      this.homeApproval = res.data;
      this.allObjects = this.allObjects.concat(this.homeApproval);
    });
  }

  getHeroMaster() {
    this.service.getHeroMaster().subscribe((res) => {
      this.heroMaster = res.data;
      this.allObjects = this.allObjects.concat(this.heroMaster);
    });
  }

  getKPIMaster() {
    this.service.getKPIMaster().subscribe((res) => {
      this.KPIMaster = res.data;
      this.allObjects = this.allObjects.concat(this.KPIMaster);
    });
  }

  getHomeUpdateMaster() {
    this.service.getHomeUpdateMaster().subscribe((res) => {
      this.homeUpdate = res.data;
      this.allObjects = this.allObjects.concat(this.homeUpdate);
    });
  }

  getHomeRecentMaster() {
    this.service.getHomeRecentMaster().subscribe((res) => {
      this.homeRecent = res.data;
      this.allObjects = this.allObjects.concat(this.homeRecent);
    });
  }

  getHomeRecentUploads() {
    this.service.getBulletinBoardMaster().subscribe((res) => {
      this.recentUpload = res.data;
      this.allObjects = this.allObjects.concat(this.recentUpload);
    });
  }

  getUsefulLink() {
    this.service.getUsefulLinksMaster().subscribe((res) => {
      this.homeUseFulLink = res.data;
      this.allObjects = this.allObjects.concat(this.homeUseFulLink);
    });
  }

  getTime() {
    const maxDate = new Date(
      Math.max(
        ...this.allObjects.map((element: any) => {
          return new Date(element['updated_on']);
        })
      )
    );
    this.homeService.getDate.next(maxDate);
  }

  registrationRoute() {
    this.router.navigate(['userRegistration']);
  }

  kyaRoute() {
    this.router.navigate(['kya']);
  }

  modiModalPopup() {
    this.modalService.open(this.model, {
      size: 'lg',
      centered: true,
      modalDialogClass: 'modi-modal-popup',
    });

    this.localstorageService.set('POPUP_DISMISSED', 'true');
  }

  showGalleryPopup(value: any) {
    this.isGalleryPopupShow = true;
    this.selectedGallery = value;
  }

  hideGalleryPopup() {
    this.isGalleryPopupShow = false;
  }
}
