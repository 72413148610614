import {
  API_AGENDA,
  CERTIFICATES_ROUTES,
  FC_CERTIFICATES_ROUTES,
  GENRAL_COND_ROUTES,
  REASON_FOR_AMENDMENT_ROUTES,
  REASON_OF_EXTENSION_ROUTES,
  SPECIFIC_EC_CONDITION_ROUTES,
  SPEC_COND_ROUTES,
  STANDARD_EC_CONDITION_ROUTES,
} from 'src/app/shared/app-constant';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export interface TorCertificate {
  id: number;
  date: string;
  proponent: string;
  companyname: string;
  registeredAddress: string;
  proponentEmail: string;
  fileNo: string;
  clearanceType: string;
  category: string;
  projectIncludedScheduleNo: string;
  nameOfProject: string;
  nameOfOrganization: string;
  locationOfProject: string;
  issuingAuthority: string;
  torDate: string;
  applicabilityOfGeneralConditions: string;
}

@Injectable({
  providedIn: 'root',
})
export class TorcertificateService {
  constructor(private http: HttpClient) {}

  saveStandardTorCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_STANDARD_TOR_CERTIFICATE}`,
      body
    );
  }

  getDateTime() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text',
    };
    return this.http.get<any>(
      `${environment.baseURLProcessEngine}${CERTIFICATES_ROUTES.GET_DATE_TIME}`,
      requestOptions
    );
  }

  saveStageIICLearanceLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_STAGE_II_CLEARANCE_LETTER_CERTIFICATE}`,
      body
    );
  }

  saveStageICLearanceLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_STAGE_I_CLEARANCE_LETTER_CERTIFICATE}`,
      body
    );
  }

  stageALLConditionCLearanceLetterCertificate(
    applicationId: string | number,
    type: string
  ) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_STAGE_FC_CONDITIONS}`,
      {},
      {
        params: {
          applicationId: applicationId,
          type: type,
        },
      }
    );
  }

  saveMinistryStageIICLearanceLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_MINISTRY_STAGE_II_CLEARANCE_LETTER_CERTIFICATE}`,
      body
    );
  }

  saveMinistryStageICLearanceLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_MINISTRY_STAGE_I_CLEARANCE_LETTER_CERTIFICATE}`,
      body
    );
  }

  getIROStageIICertificateOfficeName() {
    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${CERTIFICATES_ROUTES.GET_IRO_STAGE_II_OFFICE_NAME}`,
      {}
    );
  }

  saveIROStageIICLearanceLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_IRO_STAGE_II_CLEARANCE_LETTER_CERTIFICATE}`,
      body
    );
  }

  saveIROStageICLearanceLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_IRO_STAGE_I_CLEARANCE_LETTER_CERTIFICATE}`,
      body
    );
  }

  saveECAmendmentCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_AMENDMENT_CERTIFICATE}`,
      body
    );
  }

  saveEcExtensionOfValidityCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_EXTENSION_OF_VALIDITY}`,
      body
    );
  }

  saveEcMiningExtensionOfValidityCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_MINING_EXTENSION_OF_VALIDITY}`,
      body
    );
  }

  saveTransferTorCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_TRANSFER_TOR_CERTIFICATE}`,
      body
    );
  }

  saveECTransferCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_TRANSFER_CERTIFICATE}`,
      body
    );
  }

  saveECMiningTransferCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_MINING_EC_TRANSFER_CERTIFICATE}`,
      body
    );
  }

  saveECSurrenderLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_SURRENDER_LETTER_CERTIFICATE}`,
      body
    );
  }

  saveECMiningAcknowledgmentCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_MINING_ACKNOWLEDGMENT_CERTIFICATE}`,
      body
    );
  }

  saveRejectionTorCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_REJECTION_TOR}`,
      body
    );
  }

  saveECAllSectorsRejectionCertDetails(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_ALL_SECTORS_REJECTION_CERTIFICATE}`,
      body
    );
  }

  saveSpecificTorCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_SPECIFIC_TOR}`,
      body
    );
  }

  saveAmendmentTorCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_AMENDMENT_TOR}`,
      body
    );
  }

  saveFreshLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_FRESH_LETTER}`,
      body
    );
  }

  saveSplitCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_SPLIT}`,
      body
    );
  }

  saveParentSplitCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_PARENT_SPLIT}`,
      body
    );
  }

  saveEcExpansionNIPLCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_EXPANSION_NIPL}`,
      body
    );
  }

  saveMiningFreshLetterCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_MINING_FRESH_LETTER}`,
      body
    );
  }

  saveEcMiningExpansionCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_MINING_EXPANSION}`,
      body
    );
  }

  saveEcExpansionCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_EXPANSION}`,
      body
    );
  }

  saveECMiningAmendmentCertificate(body: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_EC_MINING_AMENDMENT}`,
      body
    );
  }

  getECMiningAmendmentCertDetails(propId: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_MINING_AMENDMENT_CERTIFICATE}?propId=${propId}&proposal_no=${proposalNo}`
    );
  }

  getTorCertificate(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_TOR_CERTIFICATE}?id=${id}&proposal_no=${proposalNo}`
    );
  }

  getBarcode(id: number, proposalNo: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GENERATE_QR_CODE}?proposalId=${id}&proposalNo=${proposalNo}`,
      {}
    );
  }

  getStandardBarcode(id: any, proposalNo: any, folderDir: any) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GENERATE_QR_CODE_STANDARD}?proposalId=${id}&proposalNo=${proposalNo}`,
      { folderDir }
    );
  }

  genIdentificationNo(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_TOR_IDENTIFICATION_NUMBER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  genIdentificationNoForm6(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_EXTENSION_VALIDITY_IDENTIFICATION_NUMBER_FOR}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  genIdentificationNoByFormECAmendment(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_AMENDMENT_IDENTIFICATION_NUMBER_FOR}?propId=${id}`
    );
  }

  genIdentificationNoByFormECMinningExpancion(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_MINING_EXPANSION_IDENTIFICATION_NUMBER_FOR}?propId=${id}`
    );
  }
  genIdentificationNoByFormECMinningFreshLetter(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_MINING_FRESH_LETTER_IDENTIFICATION_NUMBER_FOR}?propId=${id}`
    );
  }

  genIdentificationNoByForm1(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_FRESH_LETTER_IDENTIFICATION_NUMBER_FOR}?propId=${id}`
    );
  }

  genIdentificationNoByForm11(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_IDENTIFICATION_NUMBER_FORM_11}?propId=${id}`
    );
  }

  genIdentificationNoByForm12(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_IDENTIFICATION_NUMBER_FORM_12}?propId=${id}`
    );
  }

  genIdentificationNoByForm13(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_IDENTIFICATION_NUMBER_FORM_13}?propId=${id}`
    );
  }

  genIdentificationNoByForm8(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_TOR_IDENTIFICATION_NUMBER_FOR}?propId=${id}`
    );
  }

  genIdentificationNoByForm7(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_TOR_IDENTIFICATION_NUMBER_TRANSFER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  genIdentificationNoByForm9(id: number) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_TOR_IDENTIFICATION_NUMBER_MINING}?propId=${id}`
    );
  }

  saveSpecificTor(body: TorCertificate) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_SPECIFIC_TOR}`,
      body
    );
  }

  saveRejectionTor(body: TorCertificate) {
    return this.http.post<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.SAVE_REJECTION_TOR}`,
      body
    );
  }

  getStandTorCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_STANDARD_TOR}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getStandTorCertDetailsCertificateId(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_STANDARD_TOR_ID}?certId=${id}&proposal_no=${proposalNo}`
    );
  }

  getStageIIClearanceLetterCertDetails(
    id: number,
    proposalNo: any,
    office_type: any,
    form_type: any
  ) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_STAGE_II_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}&office_type=${office_type}&form_type=${form_type}`
    );
  }

  getStageIClearanceLetterCertDetails(
    id: number,
    proposalNo: any,
    office_type: any,
    form_type: any
  ) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_STAGE_I_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}&office_type=${office_type}&form_type=${form_type}`
    );
  }

  getStageIIClearanceLetterFormType(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_STAGE_II_FORM_TYPE_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getMinistryStageIIClearanceLetterCertDetails(
    id: number,
    proposalNo: any,
    office_type: any,
    form_type: any
  ) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_MINISTRY_STAGE_II_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}&office_type=${office_type}&form_type=${form_type}`
    );
  }

  getMinistryStageIClearanceLetterCertDetails(
    id: number,
    proposalNo: any,
    office_type: any,
    form_type: any
  ) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_MINISTRY_STAGE_I_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}&office_type=${office_type}&form_type=${form_type}`
    );
  }

  getMinistryStageIIClearanceLetterFormTypeId(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_MINISTRY_STAGE_II_FORM_TYPE_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getIROStageIIClearanceLetterCertDetails(
    id: number,
    proposalNo: any,
    office_type: any,
    form_type: any
  ) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_IRO_STAGE_II_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}&office_type=${office_type}&form_type=${form_type}`
    );
  }

  getIROStageIClearanceLetterCertDetails(
    id: number,
    proposalNo: any,
    office_type: any,
    form_type: any
  ) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_IRO_STAGE_I_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}&office_type=${office_type}&form_type=${form_type}`
    );
  }

  getFormGCertByPropId(id: number, proposalNo: any, office_type: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_FORM_G_IRO_STAGE_I_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}&office_type=${office_type}`
    );
  }

  getIROStageIIClearanceLetterFormTypeId(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_IRO_STAGE_II_FORM_TYPE_CLEARANCE_LETTER_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getEcExtensionOfValidityCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_EXTENSION_OF_VALIDITY}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getEcMiningExtensionOfValidityCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_Mining_EXTENSION_OF_VALIDITY}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getTransferTorCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_TRANSFER_TOR}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getECTransferCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_TRANSFER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getMiningECTransferCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_MINING_EC_TRANSFER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getECSurrenderLetterCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_SURRENDER_LETTER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getECMiningAcknowledgmentCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_MINING_ACKNOWLEDGMENT}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getECAllSectorsRejectionCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_ALL_SECTORS_REJECTION_CERTIFICATE}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getRejectionTorCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_REJECTION_TOR}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getSpecificTorCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_SPECIFIC_TOR}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getAmendmentTorCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_AMENDMENT_TOR}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getEcAmendmentCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_AMENDMENT}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getFreshLetterCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_FRESH_LETTER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getSplitCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_SPLIT}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getCrzFreshLetterDetails(propId: number, proposal_no: any) {
    return this.http.get<any>(
      environment.baseURL + CERTIFICATES_ROUTES.GET_CRZ_FRESH_LETTER,
      {
        params: { propId, proposal_no },
      }
    );
  }

  getECSurrenderCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_SURRENDER_LETTER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getECCorringendumCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_CORRINGENDUM_LETTER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  saveCrzFreshLetterDetails(body: any) {
    return this.http.post<any>(
      environment.baseURL + CERTIFICATES_ROUTES.SAVE_CRZ_FRESH_LETTER,
      body
    );
  }

  saveSurrenderLetterDetails(body: any) {
    return this.http.post<any>(
      environment.baseURL + CERTIFICATES_ROUTES.SAVE_SURRENDER_LETTER,
      body
    );
  }

  saveCorringendumLetter(body: any) {
    return this.http.post<any>(
      environment.baseURL + CERTIFICATES_ROUTES.SAVE_CORRINGENDUM_LETTER,
      body
    );
  }
  saveFcCorringendumLetterMinistry(body: any) {
    return this.http.post<any>(
      environment.baseURL + FC_CERTIFICATES_ROUTES.FC_CORRIGENDUM_SAVE,
      body
    );
  }

  getCrzRejectionLetterDetails(propId: number, proposal_no: any) {
    return this.http.get<any>(
      environment.baseURL + CERTIFICATES_ROUTES.GET_CRZ_REJECTION_LETTER,
      {
        params: { propId, proposal_no },
      }
    );
  }

  saveCrzRejectionLetterDetails(body: any) {
    return this.http.post<any>(
      environment.baseURL + CERTIFICATES_ROUTES.SAVE_CRZ_REJECTION_LETTER,
      body
    );
  }

  getCrzRecommendationLetterDetails(propId: number, proposal_no: any) {
    return this.http.get<any>(
      environment.baseURL + CERTIFICATES_ROUTES.GET_CRZ_FRESH_LETTER,
      {
        params: { propId, proposal_no },
      }
    );
  }

  saveCrzRecommendationLetterDetails(body: any) {
    return this.http.post<any>(
      environment.baseURL + CERTIFICATES_ROUTES.SAVE_CRZ_FRESH_LETTER,
      body
    );
  }

  getEcExpansionNIPLTorCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_EXPANSION_NIPL}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getEcExpansionCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_EXPANSION}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getEcMiningExpansionCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_EC_MINING_EXPANSION}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getMiningFreshLetterTorCertDetails(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_MINING_FRESH_LETTER}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  getIndustryProposalDetailByEcId(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURL}${CERTIFICATES_ROUTES.GET_INDUS_PROPOSAL_BY_PROP_ID}?propId=${id}&proposal_no=${proposalNo}`
    );
  }

  agendaProposalCondition(proposal_id: number) {
    let url = `${environment.baseURLAgendaMom}${API_AGENDA.GET_EC_CONDITION_LIST}?proposal_id=${proposal_id}`;
    return this.http.post<any>(url, {});
  }

  agendaProposalConditionByProposalId(proposal_id: number) {
    let url = `${environment.baseURLAgendaMom}${API_AGENDA.GET_EC_CONDITION_LIST_BY_PROPOSAL_ID}?id=${proposal_id}`;

    // url = `${environment.baseURLAgendaMom}${API_AGENDA.GET_EC_CONDITION_LIST_BY_PROPOSAL_ID_V2}?id=${proposal_id}`;

    console.log('urlrrrrr', url);
    return this.http.post<any>(url, {});
  }

  getGenralConditionByAactivityId(activityId: number) {
    return this.http.get<any>(
      `${environment.baseURL}${GENRAL_COND_ROUTES.GET_GEN_COND}?activityId=${activityId}&proposalType=TOR`
    );
  }

  getAllGeneralCondition(type: string, proponentId: any) {
    let url = `${environment.baseURL}${API_AGENDA.GET_FC_ALL_CONDITION_LIST}`;
    let param = { conditionType: type, applicationId: proponentId };
    return this.http.get<any>(url, { params: param });
  }

  getSpecificConditionByAppIdAndProposalNo(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURLMis}${SPEC_COND_ROUTES.GET_SPEC_COND}?appHistoryId=${id}&proposalNumber=${proposalNo}`
    );
  }

  getReasonsOfExtensionInValidityByAppIdAndProposalNo(
    id: number,
    proposalNo: any
  ) {
    return this.http.get<any>(
      `${environment.baseURLMis}${REASON_OF_EXTENSION_ROUTES.REASON_OF_EXTENSION_}?appHistoryId=${id}&proposalNumber=${proposalNo}`
    );
  }

  getStandardEcConditionByAppIdAndProposalNo(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURLMis}${STANDARD_EC_CONDITION_ROUTES.GET_STANDARD_EC_COND}?appHistoryId=${id}&proposalNumber=${proposalNo}`
    );
  }

  getSpecificEcConditionByAppIdAndProposalNo(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURLMis}${SPECIFIC_EC_CONDITION_ROUTES.GET_SPECIFIC_EC_COND}?appHistoryId=${id}&proposalNumber=${proposalNo}`
    );
  }

  getReasonsForAmendmentByAppIdAndProposalNo(id: number, proposalNo: any) {
    return this.http.get<any>(
      `${environment.baseURLMis}${REASON_FOR_AMENDMENT_ROUTES.GET_REASON_FOR_AMENDMENT}?appHistoryId=${id}&proposalNumber=${proposalNo}`
    );
  }

  getClearanceLetterbyproposalno(proposalno: string) {
    return this.http.get<any>(
      `${environment.baseURLMis}${FC_CERTIFICATES_ROUTES.GET_CLEARANCE_LETTER_COND}?proposalno=${proposalno}`
    );
  }

  getClearanceCertificateDetails(
    proponentId: number,
    officeType: string,
    formType: string
  ) {
    return this.http.get<any>(
      `${environment.baseURLMis}${FC_CERTIFICATES_ROUTES.GET_CLEARANCE_CERTIFICATES_DETAILS}?id=${proponentId}&office_tyep_abbreviation=${officeType}&formType=${formType}`
    );
  }
  getFcCorrigendumCertificateDetails(
    proponentId: number,
    proposalNo: string,
    officeType: string,
    corrigendumId: any
  ) {
    return this.http.post<any>(
      `${environment.baseURL}${FC_CERTIFICATES_ROUTES.FC_CORRIGENDUM_GET}?propId=${proponentId}&proposalNo=${proposalNo}&officeType=${officeType}&corrigendumId=${corrigendumId}`,
      {}
    );
  }
  getApprovingAuthority(proposal_id: number) {
    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${CERTIFICATES_ROUTES.GET_APPROVING_AUTHORITY}?applicationId=${proposal_id}`,
      {}
    );
  }

  getApprovingAuthorityV2(proposal_id: number) {
    return this.http.post<any>(
      `${environment.baseURLProcessEngine}${CERTIFICATES_ROUTES.GET_APPROVING_AUTHORITY_V2}?applicationId=${proposal_id}`,
      {}
    );
  }

  generatePDF() {
    const data = document.getElementById('data-container');
    const fileName = 'Clearance-Letter.pdf';
    this.createPdf(data, fileName);
  }

  async createPdf(data: any, fileName: string, torNum?: any) {
    var doc = new jsPDF('p', 'mm', 'a4');
    var position = 0;

    await this.generateDoc(data, position, doc);
    this.addFooters(doc, torNum);
    doc.save(fileName);
  }

  async generateDoc(data: any, position: number, doc: any) {
    const canvas = await html2canvas(data!);
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 300;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;

    doc.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight + 10);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight + 10);
      heightLeft -= pageHeight;
    }
    return position;
  }

  addFooters(doc: any, torNum?: any) {
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.text(
        `${torNum} ${pageCount}/${pageCount}`,
        60,
        doc.internal.pageSize.height - 10
      );
    }
  }

  removeCommentsFromContent(htmlContent: string) {
    if (!htmlContent) return '';
    const regex = /<!--[\s\S]*?-->/g;
    return htmlContent
      .replace('http://localhost:4200', 'https://stgdev.parivesh.nic.in')
      .replace(regex, '');
  }
  addSignKey(pdfPath: string) {
    const signPdfPath = pdfPath.replace('.pdf', '-signed.pdf');
    return signPdfPath;
  }

  convertProposalConditionToHTML(data: any) {
    let gcParaData = '';
    data.forEach((cond: any) => {
      gcParaData += `<h3>${cond.condition_heading}</h3><p>${cond.condition}</p>`;
    });
    return gcParaData;
  }

  convertGeneralConditionsToHTML(data: any) {
    let gcParaData = '';
    if (data?.length) {
      gcParaData = '<ol style="padding-left:0">';

      data.forEach((h1: any) => {
        gcParaData += `<li><b>${h1.heading}</b>`;

        if (h1.conditionHeading?.length) {
          gcParaData += `<ol style="padding-left:0">`;

          h1.conditionHeading?.forEach((h2: any) => {
            gcParaData += `<li><b>${h2.headingCondition}</b></br>`;

            if (h2.conditions?.length) {
              gcParaData += `<ol style="padding-left:0">`;

              h2.conditions?.forEach((c: any) => {
                gcParaData += `<li>${c}</li>`;
              });
              gcParaData += `</ol>`;
            }
            gcParaData += `</li>`;
          });
          gcParaData += `</ol>`;
        }
        gcParaData += `</li>`;
      });

      gcParaData += `</ol>`;
    }
    return gcParaData;
  }
}

export const parseHtmlBreak = (htmlContent: string) => {
  if (!htmlContent) return '';
  return htmlContent.replace(/\n/g, '<br>');
};
