import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AI_APIS, API, PROPOSAL_DATA } from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private http: HttpClient) { }

  getDataFromAi = (proposal_no: any): Observable<any> => this.http.get(`${environment.baseURLProcessEngine}${API.EDQ_QUERIES}`, { params: { proposalNumber: proposal_no } });

  getComplianceSummary = (): Observable<any> => this.http.get('assets/json/ai/complianceSummary.json');

  getProposalSummary = (): Observable<any> => this.http.get('assets/json/ai/proposal-summary.json')

  submitToAi = (input_Str: any, proposalNumber: string, applicationId?: any) => this.http.post(environment.baseUrlAi + AI_APIS.SAVE_TO_AI, { input_Str }).subscribe({
    next: response => {

      if (applicationId) this.saveProposalDataHistory({ id: 0, proposalNo: proposalNumber, applicationId, formDataJson: JSON.stringify(input_Str) });
      this.saveToPariveshAi({ id: 0, is_active: true, is_deleted: false, proposalNumber, responseText: JSON.stringify(response) })
    },
    error: () => {
      console.log(input_Str)
      if (applicationId) this.saveProposalDataHistory({ id: 0, proposalNo: proposalNumber, applicationId, formDataJson: JSON.stringify(input_Str) });
    }
  });

  saveToPariveshAi = (data: { id: 0, proposalNumber: string, responseText: string, is_active: true, is_deleted: false }) => this.http.post(environment.baseURLProcessEngine + AI_APIS.SAVE_TO_PARIVESH_AI, data).subscribe();

  saveProposalDataHistory = (data: any) => this.http.post<any>(environment.baseURL + PROPOSAL_DATA.HISTORY_SAVE, data).subscribe();

  getChatBotResponse = (payload: { question: string }) => this.http.post<any>(environment.misDashboard + '/ask', payload);

}
