export const regexMap = {
  regexIndivid: '[A-Za-z]{3}[Pp][A-Za-z]{1}[0-9]{4}[A-Za-z]{1}',
  regexCompany: '([A-Z]{3}[F|H|B|J][A-Z]{1}[0-9]{4}[A-Z]{1})|([A-Z]{3}[C|A|R|T][A-Z]{1}[0-9]{4}[A-Z]{1})',
  // regexCompany: '([A-Z]{3}[F|H|B|J|L][A-Z]{1}[0-9]{4}[A-Z]{1})|([A-Z]{3}[C|A|R|T][A-Z]{1}[0-9]{4}[A-Z]{1})',
  regexGovt: '[A-Z]{3}[G|L]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}',
  // regexGovt: '[A-Z]{3}G[A-Z]{1}[0-9]{4}[A-Z]{1}',
  regexLLP: '[A-Z]{3}F[A-Z]{1}[0-9]{4}[A-Z]{1}',
  regexPinCode: '[1-9]{1}[0-9]{2}[0-9]{3}',
  regexMobile: '[6789][0-9]{9}',
  regexEmailGovt: '[A-Za-z0-9-_.]{2,119}[A-Za-z0-9]@([A-Za-z0-9]+\\.){0,}(gov|nic|hpmail|jcboseust.ac|ac|ukgovernment|kmcgov|cujammu.ac|mahapwd.gov|pdkv.ac|rbi.org|aai|mail.jnu.ac|engg.du.ac|sau.ac)(.in|.aero)',
  regexUserEmail: '[a-zA-Z0-9\-\_\.]{2,63}[A-Za-z0-9]@[A-Za-z0-9-]+\\.[a-zA-Z0-9-_.]+[A-Za-z]+',
  yyMM: '^\\d{2}\\/\\d{2}$',
  regexCin: '([L|U]{1}[0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6})|([A-Za-z]{3}-[0-9]{4})',
  url: '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
  regExPassword: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
};
